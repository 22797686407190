import 'src/global.css';

// ----------------------------------------------------------------------

import { Router } from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import { CONFIG } from 'src/config-global';
import { LocalizationProvider } from 'src/locales';
import { I18nProvider } from 'src/locales/i18n-provider';
import { ThemeProvider } from 'src/theme/theme-provider';

import { Snackbar } from 'src/components/snackbar';
import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { CheckoutProvider } from 'src/sections/checkout/context';

import { AuthProvider as JwtAuthProvider } from 'src/auth/context/jwt';
import { AuthProvider as Auth0AuthProvider } from 'src/auth/context/auth0';
import { AuthProvider as AmplifyAuthProvider } from 'src/auth/context/amplify';
import { AuthProvider as SupabaseAuthProvider } from 'src/auth/context/supabase';
import { AuthProvider as FirebaseAuthProvider } from 'src/auth/context/firebase';
import React, { useEffect } from 'react';

// ----------------------------------------------------------------------

const AuthProvider =
  (CONFIG.auth.method === 'amplify' && AmplifyAuthProvider) ||
  (CONFIG.auth.method === 'firebase' && FirebaseAuthProvider) ||
  (CONFIG.auth.method === 'supabase' && SupabaseAuthProvider) ||
  (CONFIG.auth.method === 'auth0' && Auth0AuthProvider) ||
  JwtAuthProvider;

export default function App() {
  useScrollToTop();

  const [show, setShow] = React.useState(true);

  useEffect(() => {
    // after 1s, cosole.log the message
    // setTimeout(() => {
    //   setShow(false);
    // }, 1000);

  }, []);

  return (
    <>
      {
        show ? (
          <I18nProvider>
            <LocalizationProvider>
              <AuthProvider>
                <SettingsProvider settings={defaultSettings}>
                  <ThemeProvider>
                    <MotionLazy>
                      <CheckoutProvider>
                        <Snackbar />
                        <ProgressBar />
                        <SettingsDrawer />
                        <Router />
                      </CheckoutProvider>
                    </MotionLazy>
                  </ThemeProvider>
                </SettingsProvider>
              </AuthProvider>
            </LocalizationProvider>
          </I18nProvider>
        ) : (
          <>
            <h1>Bem vindo</h1>
            <h3>O servidor esta em manutenção</h3 >
          </>
        )
      }
    </>
    // <I18nProvider>
    //   <LocalizationProvider>
    //     <AuthProvider>
    //       <SettingsProvider settings={defaultSettings}>
    //         <ThemeProvider>
    //           <MotionLazy>
    //             <CheckoutProvider>
    //               <Snackbar />
    //               <ProgressBar />
    //               <SettingsDrawer />
    //               <Router />
    //             </CheckoutProvider>
    //           </MotionLazy>
    //         </ThemeProvider>
    //       </SettingsProvider>
    //     </AuthProvider>
    //   </LocalizationProvider>
    // </I18nProvider>
    // <>
    //   <h1>Bem vindo</h1>
    //   <h3>O servidor esta em manutenção</h3 >
    // </>
  );
}
