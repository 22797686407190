import { useMemo, useEffect, useCallback } from 'react';

import { useSetState } from 'src/hooks/use-set-state';

import axios, { endpoints } from 'src/utils/axios';

import { STORAGE_KEY } from './constant';
import { AuthContext } from '../auth-context';
import { setSession, isValidToken } from './utils';

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const { state, setState } = useSetState({
    user: null,
    establishment: {},
    loading: true,
    services: [],
  });

  const checkUserSession = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        setSession(accessToken);

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/estabelecimento/self`, {
          method: "GET",
          headers: {
            "Authorization": accessToken
          }
        })
        const data = await res.json();

        setState({ establishment: data });

        setState({ user: { ...data, accessToken }, loading: false });
      } else {
        setState({ user: null, loading: false });
      }
    } catch (error) {
      console.error(error);
      setState({ user: null, loading: false });
    }
  }, [setState]);

  useEffect(() => {
    checkUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addService = useCallback(async (service) => {
    try {
      setState({ services: [...state.services, service] });
    } catch (error) {
      console.error(error);
    }
  }, [state.services, setState]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user
        ? {
          ...state.user,
          role: state.user?.role ?? 'admin',
        }
        : null,
      checkUserSession,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      establishment: state.establishment,
      addService,
    }),
    [checkUserSession, addService, state.user, status, state.establishment]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}